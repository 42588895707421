import React from 'react';
import { Link } from "gatsby"

// import Button from './Button';

const PostListItem = props => {
    
  let postImg;
  
  if( props.image ) {
    postImg = <img src={props.image} alt="Post Image" />;
  }
  
  return (
    <div className="post-item">
      {postImg}
      <div className="post-blurb">
        <h2 dangerouslySetInnerHTML={{ __html: props.title}} />
        <div className="excerpt" dangerouslySetInnerHTML={{ __html: props.excerpt }} />
      </div>
      <div className="post-link">
        <Link to={props.url} className="button">
          Read More
        </Link>
      </div>
    </div>
  )
}

export default PostListItem
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import logo from '../images/logo.svg'

const Header = ({ siteTitle }) => (
  <header className="site-header">
    <div className="container">
      <div className="row">
        <div className="grid-item logo">
          <Link to="/">
            <img className="logo" src={logo} alt={siteTitle} />
          </Link>
        </div>
        {/* <div className="menu">
          <ul>
            {menuItems}
          </ul>
        </div>
        <div className="menu-toggle"></div> */}
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

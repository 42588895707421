import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import FeaturedHero from '../components/FeaturedHero/FeaturedHero'
import PostList from '../components/PostList/PostList'
import SEO from "../components/seo"

const IndexPage = (props) => (
  <Layout>
    <FeaturedHero></FeaturedHero>
    <PostList posts={props.data.allWordpressPost.edges}></PostList>
  </Layout>
)

export default IndexPage

export const homePageQuery = graphql`
  query {
    allWordpressPost(sort: {
      fields: [date]
      order: DESC
    }) {
      edges {
        node {
          title
          excerpt
          slug
          date
          featured_media {
            source_url
          }
        }
      }
    }
  }
`
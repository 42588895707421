import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

const FeaturedHero = () => (
  <StaticQuery
    query={
      graphql`
        query {
          wordpressPost(tags: {elemMatch: {name: {eq: "featured"}}}) {
            title
            excerpt
            slug
            date
            featured_media {
              source_url
            }
            hero_photo {
              source_url
            }
          }
        }
      `
    }
    render = {data => {
      const post = data.wordpressPost

      if (!post) {
        return(<></>)
      }
      
      const { title, excerpt, slug, hero_photo = '', featured_media } = post

      let postImg;
      if ( featured_media ) {
        postImg = <img className="mobile-img" src={featured_media.source_url} alt="Post Image" />;
      }

      return (
        <div className="hero featured-hero" style={{backgroundImage: `url(${hero_photo.source_url})`}}>
          {postImg}
          <div className="container">
            <div>
              <span className="featured-label">Featured Story</span>
              <h1 dangerouslySetInnerHTML={{ __html: title}} />
              <div className="excerpt" dangerouslySetInnerHTML={{ __html: excerpt }} />
              <Link to={slug} className="button">
                Read More
              </Link>
            </div>
          </div>
        </div>
      )
    }}
  />
)

export default FeaturedHero
import React from 'react';
import PostListItem from '../PostListItem/PostListItem';
import './PostList.scss';

const PostList = props => {
  const posts = props.posts || []
    
  const thesePosts = posts.map((node, index) => {
    const post = node.node
    let featImg;

    if (post.featured_media) {
      featImg = post.featured_media.source_url
    }
    
    // To-do: find a better way to deal with HTML entities in post titles
    return(
      <li className="grid-item" key={index}>
        <PostListItem title={post.title} url={post.slug} excerpt={post.excerpt} image={featImg}/>
      </li>
    )
  })
    
  return (
    <div className="posts container">
      <ul className="row post-list">
        {thesePosts}
      </ul>
    </div>
  )
}

export default PostList;